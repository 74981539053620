@font-face {
    font-family: 'Poppins';
    src: url('https://cdn.avt.pl/fonts/PoppinsLight.eot');
    src: url('https://cdn.avt.pl/fonts/PoppinsLight.eot') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/PoppinsLight.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/PoppinsLight.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/PoppinsLight.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/PoppinsLight.svg#PoppinsLight') format('svg');
    font-weight: 300;
}

@font-face {
    font-family: 'Poppins';
    src: url('https://cdn.avt.pl/fonts/PoppinsRegular.eot');
    src: url('https://cdn.avt.pl/fonts/PoppinsRegular.eot') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/PoppinsRegular.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/PoppinsRegular.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/PoppinsRegular.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/PoppinsRegular.svg#PoppinsRegular') format('svg');
    font-weight: 400;
}

@font-face {
    font-family: 'Poppins';
    src: url('https://cdn.avt.pl/fonts/PoppinsMedium.eot');
    src: url('https://cdn.avt.pl/fonts/PoppinsMedium.eot') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/PoppinsMedium.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/PoppinsMedium.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/PoppinsMedium.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/PoppinsMedium.svg#PoppinsMedium') format('svg');
    font-weight: 500;
}

@font-face {
    font-family: 'Poppins';
    src: url('https://cdn.avt.pl/fonts/PoppinsSemiBold.eot');
    src: url('https://cdn.avt.pl/fonts/PoppinsSemiBold.eot') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/PoppinsSemiBold.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/PoppinsSemiBold.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/PoppinsSemiBold.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/PoppinsSemiBold.svg#PoppinsSemiBold') format('svg');
    font-weight: 600;
}

@font-face {
    font-family: 'Poppins';
    src: url('https://cdn.avt.pl/fonts/PoppinsBold.eot');
    src: url('https://cdn.avt.pl/fonts/PoppinsBold.eot') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/PoppinsBold.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/PoppinsBold.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/PoppinsBold.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/PoppinsBold.svg#PoppinsBold') format('svg');
    font-weight: 700;
}
@font-face {
    font-family: 'Roboto';
    src: url('https://cdn.avt.pl/fonts/RobotoThin.eot');
    src: url('https://cdn.avt.pl/fonts/RobotoThin.eot') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/RobotoThin.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/RobotoThin.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/RobotoThin.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/RobotoThin.svg#RobotoThin') format('svg');
    font-weight: 100;
}
@font-face {
    font-family: 'Roboto';
    src: url('https://cdn.avt.pl/fonts/RobotoLight.eot');
    src: url('https://cdn.avt.pl/fonts/RobotoLight.eot') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/RobotoLight.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/RobotoLight.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/RobotoLight.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/RobotoLight.svg#RobotoLight') format('svg');
    font-weight: 300;
}
@font-face {
    font-family: 'Roboto';
    src: url('https://cdn.avt.pl/fonts/RobotoRegular.eot');
    src: url('https://cdn.avt.pl/fonts/RobotoRegular.eot') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/RobotoRegular.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/RobotoRegular.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/RobotoRegular.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/RobotoRegular.svg#RobotoRegular') format('svg');
    font-weight: 400;
}
@font-face {
    font-family: 'Roboto';
    src: url('https://cdn.avt.pl/fonts/RobotoMedium.eot');
    src: url('https://cdn.avt.pl/fonts/RobotoMedium.eot') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/RobotoMedium.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/RobotoMedium.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/RobotoMedium.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/RobotoMedium.svg#RobotoMedium') format('svg');
    font-weight: 500;
}
@font-face {
    font-family: 'Roboto';
    src: url('https://cdn.avt.pl/fonts/RobotoBold.eot');
    src: url('https://cdn.avt.pl/fonts/RobotoBold.eot') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/RobotoBold.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/RobotoBold.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/RobotoBold.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/RobotoBold.svg#RobotoBold') format('svg');
    font-weight: 700;
}
@font-face {
    font-family: 'Roboto';
    src: url('https://cdn.avt.pl/fonts/RobotoBlack.eot');
    src: url('https://cdn.avt.pl/fonts/RobotoBlack.eot') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/RobotoBlack.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/RobotoBlack.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/RobotoBlack.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/RobotoBlack.svg#RobotoBlack') format('svg');
    font-weight: 900;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('https://cdn.avt.pl/fonts/RobotoCondensedRegular.eot');
    src: url('https://cdn.avt.pl/fonts/RobotoCondensedRegular.eot') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/RobotoCondensedRegular.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/RobotoCondensedRegular.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/RobotoCondensedRegular.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/RobotoCondensedRegular.svg#RobotoCondensedRegular') format('svg');
    font-weight: 400;
}
@font-face {
    font-family: 'Roboto Condensed';
    src: url('https://cdn.avt.pl/fonts/RobotoCondensedBold.eot');
    src: url('https://cdn.avt.pl/fonts/RobotoCondensedBold.eot') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/RobotoCondensedBold.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/RobotoCondensedBold.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/RobotoCondensedBold.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/RobotoCondensedRegular.svg#RobotoCondensedBold') format('svg');
    font-weight: 700;
}


@font-face {
    font-family: 'FontAwesome';
    src: url('https://cdn.avt.pl/fonts/fontawesome-webfont.eot?v=4.7.0');
    src: url('https://cdn.avt.pl/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/fontawesome-webfont.woff2?v=4.7.0') format('woff2'),
         url('https://cdn.avt.pl/fonts/fontawesome-webfont.woff?v=4.7.0') format('woff'),
         url('https://cdn.avt.pl/fonts/fontawesome-webfont.ttf?v=4.7.0') format('truetype'),
         url('https://cdn.avt.pl/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: normal;
    font-style: normal;
}


.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-facebook:before           { content: "\f09a"; }
.fa-facebook-square:before    { content: "\f082"; }
.fa-pinterest:before          { content: "\f0d2"; }
.fa-instagram:before          { content: "\f16d"; }
.fa-google-plus:before        { content: "\f0d5"; }
.fa-shopping-cart:before      { content: "\f07a"; }
.fa-newspaper-o:before        { content: "\f1ea"; }
.fa-search:before             { content: "\f002"; }
.fa-link:before               { content: "\f0c1"; }
.fa-font:before               { content: "\f031"; }
.fa-print:before              { content: "\f02f"; }
.fa-check:before              { content: "\f00c"; }
.fa-chevron-right:before      { content: "\f054"; }
.fa-chevron-left:before       { content: "\f053"; }
.fa-times:before              { content: "\f00d"; }
.fa-chevron-down:before       { content: "\f078"; }
.fa-times-circle:before       { content: "\f057"; }
.fa-clock-o:before            { content: "\f017"; }
.fa-play:before               { content: "\f04b"; }
.fa-caret-down:before         { content: "\f0d7"; }
