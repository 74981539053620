@import 'abstracts/variables';
@import 'base/typography';

*, *::before, *::after {
    box-sizing: border-box;
}
html, body { height: 100%; background-color: #fff; }
body       { margin: 0; }

.error-main {
    font-family: $font-main;
}
.error {
    &-header {
        height: 186px;
        padding: 0 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        .logo {
            display: block;
            width: 370px;
            max-width: 100%;
            img {
                max-width: 100%;
                height: auto;
            }
        }
    }

    &-main {
        width: 100%;
        min-height: calc(100vh - 186px);
        padding: 43px 0;
        background: url('../images/bg-404.jpg') 50%/cover no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        
        .content-wrapper {
            max-width: 950px;
            padding: 0 15px;
            margin: 0 auto;
            text-align: center;
            a {
                color: $color-orange;
                transition: color .25s ease-in;
                text-decoration: underline;
            }
        }
    }

    &-message {
        margin: 0;
        color: #fff;
        font-size: 20px;
        font-weight: normal;
        @media(min-width: $md) {
            font-size: 32px;
        }
    }
    &-hint {
        margin: 30px 0 0;
        font-size: 20px;
        @media(min-width: $md) {
            font-size: 32px;
        }
    }

    &-code {
        margin: 30px 0 0;
        font-size: 140px;
        font-weight: 700;
        line-height: 1;
        color: $color-orange;
        @media (min-width: $md) {
            font-size: 200px;
        }
    }
}

.search {
    width: 100%;
    max-width: 370px;
    margin: 26px auto 0;
    position: relative;
    z-index: 1;
    
    &-form {
        display: flex;
        border-radius: 24px;
        box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.5);
    }
    &-input {
        flex: 1 1 auto;
        width: 1%;
        background: #fff;
        color: rgba(26, 27, 28, 1);
        height: 48px;
        padding: 0 15px 0 30px;
        border-top-left-radius: 24px;
        border-bottom-left-radius: 24px;
        border: none;
        font-size: 15px;
        outline: none;
    }

    &-button {
        flex: 0 0 56px;
        max-width: 56px;
        height: 48px;
        padding: 0;
        background: #fff;
        border-top-right-radius: 24px;
        border-bottom-right-radius: 24px;
        border: none;
        color: rgba(26, 27, 28, 1);
        outline: none;
        &:hover,
        &:focus {
            cursor: pointer;

        }
    }
}

.debug {
    margin-top: 30px;
    text-align: left;
    overflow-x: auto;
    background-color: $color-gray;
    padding: 30px 0;
    .table {
        margin: 0 auto;
        td       { padding: 2px 5px; }
        tr:hover { background: rgba($color: #fff, $alpha: 0.25); }
    }

    &-error {
        @extend .error-message;
        text-align: center;
        &-code { font-weight: 700; }
    }
}