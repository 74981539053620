$color-white: rgba(255,255,255, 1);
$color-black: rgba(26, 27, 28, 1);
$color-black-0: rgba(0,0,0,1);

$color-gray-border: rgba(26,27,28,0.12);
$color-gray-light: rgba(101, 127, 127, 0.19);
$color-gray: rgba(31, 62, 87, 0.1);
$color-gray-bg: #E5E5E5;
$color-gray-dark: #707070;


$color-bg-main: #F8F8FA;


$color-orange: rgba(252, 168, 0, 1);
$color-red: rgba(247, 43, 40, 1);

$color-blue-light: #8396A6;
$color-blue: #35546E;
$color-blue-dark: #1F3E57;
$color-blue-navy: #082134;
$color-font-main: #1a1b1c;


$font-main: 'Poppins', sans-serif;
$font-second: 'Roboto', sans-serif;

$xs-max:    575.98px;
$sm:        576px;
$sm-max:    767.98px;
$md:        768px;
$md-max:    1024.98px;
$lg:        1025px;
$lg-max:    1199.98px;
$xl:        1200px;
